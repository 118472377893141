<app-json-ld-component *ngIf="organizationSchema" [json]="organizationSchema"></app-json-ld-component>
<app-json-ld-component *ngIf="breadcrumbSchema" [json]="breadcrumbSchema"></app-json-ld-component>
<app-json-ld-component *ngIf="reviewSchema" [json]="reviewSchema"></app-json-ld-component>
<div [ngClass]="{'hide-nav-links' : showHeaderSection}">
    <div id="wrapper" [ngClass]="{'show-backdrop show-search-results lock-scrolling': showGlobalSearch }"
        (clickoutside)="hideGlobalSearch();showCart = false; showMenu = false; showSubMenu = false" [enableWhiteListing]="enableWhiteListing">
        <!-- Loader Component -->
        <app-loader></app-loader>
        <form [formGroup]="searchForm">
            <!-- Mobile Header -->

            <!-- Mobile Header -->

            <!-- Desktop Header -->
            <header id="header-content" class="header  lg:block fixed w-full bg-white select-none js-site-header">
                <a type="button" id="first-tab-index" tabindex="1" ></a> 
                <button class="skip-to-main-content-link button button--small button--fill opacity-0" (focus)="onFocus()" type="button"  [class.opacity-100]="isFocused" (blur)="onBlur()" (click)="focusOnMainContent()" tabindex="2" >Skip to main content</button>
                <div *ngIf="toasterNotification && toasterNotification.content.length > 0"
                    class="free-shipping-notification"
                    [ngClass]="{'header__free-shipping-banner': toasterNotification === undefined && toasterNotification.content.length <= 0}">
                    <span *ngFor="let toaster of toasterNotification.content">
                        {{toaster.content}} 
                    </span>
                </div>
                <div class="free-shipping-notification" style="z-index: 100;" *ngIf="!isConnected">
                    Your request failed. Please make sure you're connected to the internet and try again.
                </div>

                <!-- Menu Section -->
                <div class="container container-padding mx-auto pb-2">

                    <div class="flex items-center">
                        <!-- JD Logo for Desktop -->
                        <div class="w-1/3 lg:hidden flex items-center header-mobile__left">
                            <a (click)="showMobileMenu = !showMobileMenu"
                                class="no-underline text-black header-menu-toggle flex items-center js-toggle-lock-scrolling"
                                data-toggle-active=".mobile-menu">MENU</a>
                        </div>
                        <div class="w-1/7 header__left">
                            <a id="header__logo_wrapper" [routerLink]="['/home']" (focus)="hideGlobalSearch()" tabindex="3">
                                <img sameSite width="100" height="70" [src]="'./../assets/img/header-logo.webp'" appImageLazyLoad loading="lazy"
                                    alt="Jamestown Distributors" class="header__logo">
                            </a>
                        </div>
                        <!-- JD Logo for Desktop -->

                        <div class="w-3/5 header__middle" *ngIf="!showHeaderSection">
                            <!-- Global Search -->
                            <div
                                class="py-2 relative header-search w-3/4 mx-auto header-search--desktop scroll-hide js-search-form">
                                <input id="header-search-input-field" placeholder="Search" formControlName="query" autocomplete="off"
                                    (paste)="onPaste($event)" (keyup)="search($event)"
                                    class="w-full header-search__input whitelisted" data-toggle-search tabindex="4">
                                <button aria-label="Search" class="header-search__button whitelisted"
                                    [disabled]="isSearchTextEntered" type="button"
                                    (click)="navigateWithQuery(searchForm.controls.query.value, true)">
                                    <i aria-hidden="true"
                                        class="fal fa-search header-search__button__icon whitelisted"></i>
                                </button>
                            </div>
                            <!-- Global Search -->

                            <!-- Menu Section -->
                            <div class="justify-between flex relative header__middle__nav"
                                *ngIf="!showMobileNavigation">
                                <a
                                    (click)="shopClickMenu()"
                                    (keyup.escape)="showMenu = false;showSubMenu = false"
                                    (mouseover)="showMenu = true;showSubMenu = true"
                                    (mouseout)="showMenu = false;showSubMenu = false"
                                    class="text-black no-underline font-display text-lg lg:text-22px relative block js-shop-menu-item"
                                    [ngClass]="{'active': showMenu}"
                                    role="application"
                                    aria-label="shop link"
                                    data-hover-toggle=".header-dropdown--shop, .js-shop-menu-item" [tabindex]="searchResultTabIndex == 0 ? 6 : searchResultTabIndex + 2" id="header-shop-menu">SHOP</a>
                                <a [routerLink]="['/product/brand/totalboat-page']" routerLinkActive="active"
                                (focus)="showMenu =  false" [attr.aria-current]="router.url.includes('/product/brand/totalboat-page') && 'page'"
                                    class="text-black no-underline font-display text-lg lg:text-22px relative block" [tabindex]="tabIndex + 8">TOTALBOAT</a>
                                <a [href]="productSupportURL" target="_blank"
                                (focus)="showMenu =  false"
                                    class="text-black no-underline font-display text-lg lg:text-22px relative block" [tabindex]="tabIndex + 9">PRODUCT
                                    SUPPORT</a>
                                <a [href]="projectGuidesURL" target="_blank"
                                (focus)="showMenu =  false"
                                    class="text-black no-underline font-display text-lg lg:text-22px relative block" [tabindex]="tabIndex +  10">PROJECT
                                    GUIDES</a>
                                <a [href]="jdProURL" target="_blank"
                                    (focus)="showMenu =  false"
                                    class="text-black no-underline font-display text-lg lg:text-22px relative block"[tabindex]="tabIndex +  11">JDPRO</a>

                                <div role="application" aria-label="menu" class="bg-white dropdown-menu header-dropdown--shop shop-header-dropdown"
                                    [ngClass]="{'active': showMenu}" (mouseover)="showMenu = true"
                                    (mouseout)="showMenu = false"
                                    data-hover-toggle=".header-dropdown--shop, .js-shop-menu-item" id="shop-menu-container">

                                    <div class="free-shipping-notification" (mouseover)="showSubMenu = true">
                                        <span> Press / + s together to Open Menu  </span>
                                    </div>
  
                                    <!-- Category menu -->
                                    <div class="dropdown-menu__main-menu bg-white mt-5 overflow-auto" (keyup.escape)="showMenu = false;showSubMenu = false">
                                        <span *ngFor="let menu of menuCategoryList; let i = index">
                                            <a *ngIf="subMenuCategoryList && subMenuCategoryList?.url" 
                                                [tabindex]="menu.tabIndex"
                                                class="flex no-underline text-xl text-black justify-between items-center p-3 dropdown-menu__link"
                                                data-toggle-submenu="#showSubMenu"
                                                (focus)="subMenuCategoryList = menu;showSubMenu = true;"
                                                (mouseover)="subMenuCategoryList = menu;showSubMenu = true"
                                                (mouseout)="showSubMenu = true" [routerLink]="[menu?.url]"
                                                (click)="navigateToProductLanding(subMenuCategoryList, '')"
                                                (keyup.arrowup)="arrowDownMenu($event, 'ArrowUp')"
                                                (keyup.arrowdown)="arrowDownMenu($event, 'ArrowDown')"
                                                [id]="'shop-menu-category-' + i">
                                                <span class="text-sm">{{menu.name}}</span> <i
                                                    class="fal fa-chevron-right text-grey-dark"></i>
                                            </a>
                                        </span>
                                    </div>
                                    <!-- Category menu -->
  
                                    <div class="bg-grey-blue dropdown-menu__submenu justify-between flex-col mt-5"
                                        [ngClass]="{'active': showSubMenu,'hidden': !showSubMenu}" (keyup.escape)="showMenu = false;showSubMenu = false">
                                        <!-- Sub Category Menu -->
                                        <div class="bg-grey-blue dropdown-menu_submenu_inner" id="showSubMenu">
                                            <span *ngFor="let subMenu of subMenuCategoryList?.subcategories">
                                                <a *ngIf="subMenu && subMenu?.url" [routerLink]="[subMenu?.url]"
                                                    (click)="navigateToProductLanding(subMenuCategoryList, subMenu)"
                                                    [tabindex]="subMenu.tabIndex"
                                                    (mouseout)="showMenu = true"
                                                    (keyup.arrowup)="arrowDownMenu($event, 'ArrowUp')"
                                                    (keyup.arrowdown)="arrowDownMenu($event, 'ArrowDown')"
                                                    class="no-underline text-xl text-white flex p-3 dropdown-menu_submenu_link">
                                                    <span class="text-sm">{{subMenu.name}}</span>
                                                </a>
                                            </span>
                                        </div>
                                        <!-- Sub Category Menu -->
                                    </div>
                                </div>
                            </div>
                            <!-- Menu Section -->
                        </div>

                        <!-- User related info -->
                        <div
                            class="w-1/5 text-grey-darker header__right text-right lg:block flex flex-nowrap justify-end items-center">
                            <div class="mt-4 mb-6 contact-number md:block hidden scroll-hide"><i
                                    class="fal fa-mobile mr-2"></i>
                                <a href="tel:18004970010" (focus)="hideGlobalSearch(); showMenu = false" [tabindex]="searchResultTabIndex === 0 ? 5 : searchResultTabIndex + 1">1.800.497.0010</a>
                                <br>
                            </div>
                            <span class=" flex flex-nowrap items-center justify-end header__right__line-2">
                                <!-- Global Search -->
                                <div class="mr-6 w-1/2 header-search relative xl-scroll-show js-search-form">
                                    <input id="search-input-field-min" placeholder="Search" (keydown.enter)="stopScrolling($event)" (keyup)="search($event)" (paste)="onPaste($event)"
                                        formControlName="query" autocomplete="off"
                                        class="w-full header-search__input whitelisted" data-toggle-search>
                                    <button aria-label="Search" class="header-search__button whitelisted"
                                        [disabled]="isSearchTextEntered" type="button"
                                        (click)="navigateWithQuery(searchForm.controls.query.value, true)">
                                        <i aria-hidden="true"
                                            class="fal fa-search header-search__button__icon whitelisted"></i>
                                    </button>
                                </div>
                                <!-- Global Search -->

                                <button (click)="showHideGlobalSearch(true);"
                                    data-toggle-search=".header__search-dropdown, .header__toggle-search"
                                    class="no-underline text-grey-darker hidden header__toggle-search header__toggle-search--desktop scroll-show whitelisted">
                                    <i class="fal fa-search whitelisted" title="Search"></i>
                                </button>


                                <div class="justify-end flex relative header__middle__nav">
                                    <a *ngIf="!isLoggedIn" (click)="loginPopup('open')"
                                        (focus)=" showCart = false" aria-label="login"
                                        (keyup.enter)="loginPopup('open')"
                                        id="login-header-menu"
                                        class="no-underline text-grey-darker cursor-pointer hide-logged-in js-login-menu-item px-3"
                                        data-hover-toggle=".header-dropdown--login, .js-login-menu-item" [tabindex]="tabIndex + 13">
                                        <i class="fal fa-user lg:mr-2"></i>
                                        <span class="scroll-hide hidden xl:inline-block">{{userName}}</span>
                                    </a>
                                    <a *ngIf="isLoggedIn" [ngClass]="{'active': showAccountMenu}" [attr.aria-label]="userName"
                                        (focus)="showAccountMenu = true; showCart = false"
                                        (mouseover)="showAccountMenu = true" (mouseout)="showAccountMenu = false"
                                        class="no-underline text-grey-darker cursor-pointer hide-logged-in js-login-menu-item px-3"
                                        data-hover-toggle=".header-dropdown--login, .js-login-menu-item"  [tabindex]="tabIndex + 13">
                                        <i class="fal fa-user lg:mr-2"></i>
                                        <span class="scroll-hide hidden xl:inline-block">{{userName}}</span>
                                    </a>
                                    <div class="bg-white dropdown-menu header-dropdown--login"
                                        (mouseover)="showAccountMenu = true" (mouseout)="showAccountMenu = false"
                                        [ngClass]="{'active': showAccountMenu}"
                                        data-hover-toggle=".header-dropdown--login, .js-login-menu-item">
                                        <div class="dropdown-menu__main-menu bg-white"
                                            (mouseover)="showAccountMenu = true">
                                            <a [routerLink]="['/useraccount/orders']" routerLinkActive="active" [tabindex]="tabIndex + 14"
                                                class="flex no-underline text-xl text-black justify-start items-center p-3 dropdown-menu__link">
                                                <span class="my-account-nav__item__icon text-sm"><i
                                                        class="fal fa-shopping-bag"></i></span>
                                                <span class="text-sm">My Orders</span>
                                            </a>
                                            <a [routerLink]="['/useraccount/accountinfo']" routerLinkActive="active" [tabindex]="tabIndex + 15"
                                                class="flex no-underline text-xl text-black justify-start items-center p-3 dropdown-menu__link">
                                                <span class="my-account-nav__item__icon text-sm"><i
                                                        class="fal fa-info-circle"></i></span>
                                                <span class="text-sm">Account Info</span>
                                            </a>
                                            <a [routerLink]="['/useraccount/wishlist']" routerLinkActive="active" [tabindex]="tabIndex + 16"
                                                class="flex no-underline text-xl text-black justify-start items-center p-3 dropdown-menu__link">
                                                <span class="my-account-nav__item__icon text-sm"><i
                                                        class="fal fa-heart"></i></span>
                                                <span class="text-sm">My Wishlists</span>
                                            </a>
                                            <a [routerLink]="['/useraccount/addresses']" routerLinkActive="active" [tabindex]="tabIndex + 17"
                                                class="flex no-underline text-xl text-black justify-start items-center p-3 dropdown-menu__link">
                                                <span class="my-account-nav__item__icon text-sm"><i
                                                        class="fal fa-address-book"></i></span>
                                                <span class="text-sm">My Addresses</span>
                                            </a>
                                            <a [routerLink]="['/useraccount/payments']" routerLinkActive="active" [tabindex]="tabIndex + 18"
                                                class="flex no-underline text-xl text-black justify-start items-center p-3 dropdown-menu__link">
                                                <span class="my-account-nav__item__icon text-sm"><i
                                                        class="fal fa-credit-card"></i></span>
                                                <span class="text-sm">My Payments</span>
                                            </a>
                                            <a href="https://support.jamestowndistributors.com/" [tabindex]="tabIndex + 19"
                                                class="flex no-underline text-xl text-black justify-start items-center p-3 dropdown-menu__link"
                                                target="_blank">
                                                <span class="my-account-nav__item__icon text-sm"><i
                                                        class="fal fa-question-circle"></i></span>
                                                <span class="text-sm">Support</span>
                                            </a>
                                            <a (click)="logout()" [tabindex]="tabIndex + 20"
                                                class="flex no-underline text-xl text-black justify-start items-center p-3 dropdown-menu__link">
                                                <span class="my-account-nav__item__icon text-sm"><i
                                                        class="fa fa-power-off"></i></span>
                                                <span class="text-sm">Logout</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="pipe mx-2"></div>

                                <!-- Cart Popover -->
                                <div class="cart-dropdown-container hide-logged-in whitelisted" >
                                    <span class="cursor-pointer whiteliste " (focus)="getShoppingCartByIdData(); showAccountMenu = false"
                                        data-toggle-active=".cart-dropdown" [tabindex]="isLoggedIn ? tabIndex + 21 : tabIndex + 14">
                                        <span class="md:inline d-none">
                                            <i class="fal fa-shopping-cart whitelisted"></i>&nbsp;
                                        </span>
                                        <span
                                            class="bg-blue text-13px font-bold text-white rounded-full inline-block px-2 whitelisted">
                                            ${{cartValue | number: '1.2-2'}}
                                        </span>
                                    </span>
                                    <div class="shadow-lg text-center rounded-lg cart-dropdown whitelisted"
                                        aria-modal="false" [tabindex]="tabIndex + 21" [ngClass]="{'active': showCart}" role="dialog" 
                                        [attr.aria-expanded]="showCart ? true : false" id="shopping-cart" aria-labelledby="shopping-cart">
                                        <h2 id="shopping-cart" *ngIf="showCart" class="px-4 py-2 text-white bg-topography-white font-display text-22px uppercase whitelisted rounded-t-lg">
                                            Your Shopping Cart
                                        </h2>
                                        <ng-container *ngIf="cartEmptyMessage" class="whitelisted">
                                            <div class="text-center">
                                                <img [src]="'./../assets/img/cart/empty-cart-ico.png'" appImageLazyLoad
                                                    loading="lazy" class="w-1/3 mt-10" alt="empty-cart-ico" />
                                                <div class="text-lg mt-6 md:mt-2">
                                                    <strong>Your cart is currently empty</strong>
                                                </div>
                                                <p class="text-sm px-6 my-2">
                                                    Oops, it looks like you don’t have anything in your cart. Add some
                                                    items and try again.
                                                </p>

                                            </div>

                                            <div class="p-4 bg-grey-lightest rounded-b-lg">

                                                <!-- <a *ngIf="isLoggedIn" class="button button--fill block"
                                                    (click)="logout()" data-toggle-modal="#login-modal">Logout</a> -->

                                                <!-- <div class="text-center mt-2"> <strong>-->
                                                <a (keydown.tab)="showCart = false" [routerLink]="['/product']" (click)="showCart = false;"
                                                    routerLinkActive="router-link-active"
                                                    class="button button--fill block"
                                                    data-toggle-active=".cart-dropdown">Continue
                                                    Shopping</a>
                                                <!--</b> </div> -->

                                            </div>
                                        </ng-container>
                                        <div class="whitelisted" *ngIf="!cartEmptyMessage"
                                            [attr.aria-live]="annouceCartUpdated ? 'polite' : null" [attr.tabindex]="annouceCartUpdated ? 0 : null"
                                        >
                                            <div class="p-4 whitelisted" style="max-height: 40vh;overflow-y: scroll;">
                                                
                                                <div *ngIf="showCartLoader"
                                                
                                                >
                                                    Loading Cart... 
                                                    <i class="fa fa-spinner fa-spin"></i>
                                                </div>
                                                <div *ngIf="!showCartLoader && annouceCartUpdated ">
                                                    Updated Cart
                                                </div>
                                               
                                                <div *ngIf="!showCartLoader">
                                                    <div *ngFor="let product of shoppingCartByIdData?.skus; let i = index"
                                                        class="cart-dropdown__item text-left py-3 flex justify-between items-center md:py-4 whitelisted">
                                                        <div class="cart-dropdown__item__image whitelisted">
                                                            <img [src]="product?.image?.url" appImageLazyLoad loading="lazy" alt="">
                                                        </div>
                                                        <div class="cart-dropdown__item__text w-full text-black">
                                                            <div [id]="'product-name-' + i" class="mb-1 text-sm">
                                                                <strong class="whitelisted">{{product?.product?.name}}</strong>
                                                            </div>
                                                            <div  class="text-13px whitelisted">{{product?.name}}</div>
                                                            <div class="flex items-center justify-between mt-2">
                                                                <a href="javascript:void(0)" [id]="'remove-link-' + i"
                                                                    class="no-underline text-13px whitelisted"
                                                                    [attr.aria-labelledby]="'product-name-' + i + ' remove-link-' + i"
                                                                    (click)="removeFromCart(product)">Remove</a>
                                                                <div class="text-sm">
                                                                    <strong class="whitelisted">{{product.quantity}} x
                                                                        ${{product?.sellPrice}}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex items-center justify-between mt-2 text-sm text-black border-t border-grey p-4">
                                                <strong class="whitelisted">Subtotal</strong>
                                                <div class="text-13px">
                                                    <b class="whitelisted">${{subtotal.toFixed(2)}}</b>
                                                </div>
                                            </div>
                                            <div class="p-4 bg-grey-lightest rounded-b-lg">
                                                <a [routerLink]="['/checkout/info-shipping']"
                                                    [queryParams]="{ route: 'info-shipping'}"
                                                    routerLinkActive="router-link-active"
                                                    class="button button--fill block whitelisted">CHECKOUT</a>
                                                <div class="text-center mt-2">
                                                    <strong><a (keydown.tab)="showCart = false" [routerLink]="['/cart']"
                                                            routerLinkActive="router-link-active"
                                                            (click)="redirectCartPage()"
                                                            class="text-sm whitelisted">Edit
                                                            Cart</a></strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Cart Popover -->
                            </span>

                        </div>
                        <!-- User related info -->
                    </div>

                </div>
                <!-- Menu Section -->

                <!-- Search Section -->
                <div class="header__search-dropdown hidden">
                    <div class="container mx-auto py-3 container-padding">
                        <div class="py-2 header-search relative js-search-form">

                            <input id="search-input-field" placeholder="Search" (keyup)="search($event)" (paste)="onPaste($event)"
                                formControlName="query" autocomplete="off"
                                class="w-full header-search__input whitelisted" data-toggle-search>

                            <button aria-label="Search" class="header-search__button whitelisted"
                                [disabled]="isSearchTextEntered" type="button"
                                (click)="navigateWithQuery(searchForm.controls.query.value, true)">
                                <i aria-hidden="true" class="fal fa-search header-search__button__icon whitelisted"></i>
                            </button>

                        </div>
                    </div>
                </div>

                <div class="backdrop"></div>

            </header>
            <!-- Desktop Header -->
        </form>
        <!-- Mobile Menu -->
        <div class="bg-grey-lightest w-full mobile-menu" *ngIf="showMobileNavigation"
            [ngClass]="{'active': showMobileMenu}">
            <div class="mobile-menu__header p-4 bg-white flex justify-between items-center">
                <a (click)="showMobileMenu = !showMobileMenu" class="inline-flex select-none items-center no-underline font-display text-grey-darker js-toggle-lock-scrolling">
                    <i class="fal fa-times text-3xl text-grey-light mr-1"></i>
                    <span class="text-lg">CLOSE</span>
                </a>
                <a href="tel:18004970010" class="no-underline text-grey-darker">
                    <i class="fal fa-mobile mr-1"></i> 1.800.497.0010
                </a>
            </div>

            <div class="mobile-menu__inner">
                <a *ngFor="let menu of menuCategoryList" href="javascript:void(0)"
                    class="flex no-underline text-xl text-black justify-between items-center p-4 bg-white mobile-menu__link js-mobile-menu-link"
                    (click)="subMenuCategoryList = menu;showSubMenuMobileView = !showSubMenuMobileView">
                    <strong class="text-lg">{{menu.name}}</strong>
                    <i class="fal fa-chevron-right text-grey-dark"></i>
                </a>

                <div class="mobile-menu__submenu js-mobile-submenu bg-grey-blue w-full"
                    [ngClass]="{'active': showSubMenuMobileView}">
                    <div class="mobile-menu__submenu__header bg-white p-4">
                        <a (click)="showSubMenuMobileView = !showSubMenuMobileView"
                            class="inline-flex items-center no-underline font-display text-grey-darker js-mobile-submenu-back select-none">
                            <img [src]="'../assets/img/long-arrow-left-blue.svg'" appImageLazyLoad loading="lazy"
                                class="mr-1" alt="long-arrow-left-blue"/>
                            <span class="text-lg">BACK</span>
                        </a>
                    </div>

                    <div class="mobile-menu__submenu__inner bg-grey-blue justify-between flex-col">
                        <span *ngFor="let subMenu of subMenuCategoryList?.subcategories">
                            <a *ngIf="subMenu && subMenu?.url" [routerLink]="[subMenu?.url]"
                                (click)="navigateToProductLanding(subMenuCategoryList, subMenu)"
                                class="block no-underline text-xl text-white  p-4 mobile-menu__submenu__link js-mobile-submenu-link">
                                <strong class="text-lg">{{subMenu.name}}</strong>
                            </a>
                        </span>
                    </div>
                </div>

                <a class="text-black no-underline text-4xl block my-4 px-4 uppercase font-display"
                    [routerLink]="['/product/brand/totalboat-page']">Totalboat</a>

                <a [href]="productSupportURL" target="_blank"
                    class="text-black no-underline text-4xl block my-4 px-4 uppercase font-display">Product Support</a>

                <a [href]="projectGuidesURL" target="_blank"
                    class="text-black no-underline text-4xl block my-4 px-4 uppercase font-display">Project Guides</a>

                <a [href]="jdProURL" target="_blank"
                    class="text-black no-underline text-4xl block my-4 px-4 uppercase font-display">JDPro</a>

                <div class="p-4 mobile-menu__ctas">
                    <a class="button button--fill mb-4 block" *ngIf="userName == 'Login'"
                        (click)="showMobileMenu = !showMobileMenu;active = !active">
                        {{userName}}
                    </a>
                    <a class="button button--fill mb-4 block"
                        (click)="showAccountMenu = true;showMobileMenu = !showMobileMenu" *ngIf="userName != 'Login'">
                        {{userName}}
                    </a>
                    <a [routerLink]="['/cart']" routerLinkActive="router-link-active"
                        (click)="showMobileMenu = !showMobileMenu;"
                        class="button button--border button--white-text block mt-4">Shopping Cart</a>
                </div>
            </div>
        </div>
        <!-- Mobile Menu End -->

        <!-- My Account Menu -->
        <div class="bg-grey-lightest w-full mobile-menu-login" [ngClass]="{'active': showAccountMenu}">
            <div class="mobile-menu__header p-4 bg-white flex justify-between items-center">
                <a (click)="showAccountMenu = !showAccountMenu" class="inline-flex select-none items-center no-underline font-display text-grey-darker js-toggle-lock-scrolling">
                    <i class="fal fa-times text-3xl text-grey-light mr-1"></i>
                    <span class="text-lg">CLOSE</span>
                </a>
            </div>
        
            <div class="mobile-menu__inner" (click)="showAccountMenu = false">
                <a [routerLink]="['/useraccount/orders']" routerLinkActive="active" class="flex no-underline text-xl text-black p-4 bg-white mobile-menu__link">
                    <span class="my-account-nav__item__icon"><i class="fal fa-shopping-bag"></i></span> My Orders
                </a>
                <a [routerLink]="['/useraccount/accountinfo']" routerLinkActive="active" class="flex no-underline text-xl text-black p-4 bg-white mobile-menu__link">
                    <span class="my-account-nav__item__icon"><i class="fal fa-info-circle"></i></span> Account Info
                </a>
                <a [routerLink]="['/useraccount/wishlist']" routerLinkActive="active" class="flex no-underline text-xl text-black p-4 bg-white mobile-menu__link">
                    <span class="my-account-nav__item__icon"><i class="fal fa-heart"></i></span> My Wishlists
                </a>
                <a [routerLink]="['/useraccount/addresses']" routerLinkActive="active" class="flex no-underline text-xl text-black p-4 bg-white mobile-menu__link">
                    <span class="my-account-nav__item__icon"><i class="fal fa-address-book"></i></span> My Addresses
                </a>
                <a [routerLink]="['/useraccount/payments']" routerLinkActive="active" class="flex no-underline text-xl text-black p-4 bg-white mobile-menu__link">
                    <span class="my-account-nav__item__icon"><i class="fal fa-credit-card"></i></span> My Payments
                </a>
                <a class="flex no-underline text-xl text-black p-4 bg-white mobile-menu__link">
                    <span class="my-account-nav__item__icon"><i class="fal fa-question-circle"></i></span> Support
                </a>
                <a (click)="logout()" class="flex no-underline text-xl text-black p-4 bg-white mobile-menu__link">
                    <span class="my-account-nav__item__icon"><i class="fa fa-power-off"></i></span> Logout
                </a>
            </div>
        </div>        
        <!-- My Account Menu -->

        <!-- Main Content -->
       <div id="main-content">
        <router-outlet></router-outlet>
       </div>
        <!-- Main Content End -->

        <!-- Footer -->
        <footer id="footer-content" class="footer bg-black text-white py-12 md:pt-14 md:pb-2">
            <div class="container container-padding mx-auto">

                <div class="footer__cols text-center md:text-left md:flex">

                    <div class="md:w-1/2 lg:flex">

                        <div class="pb-6 lg:w-1/2 text-lg lg:text-base">

                            <div class="opacity-50 text-sm">OUR COMPANY</div>

                            <div class="my-4"><a [routerLink]="['/organization/about-us']"
                                    class="text-white no-underline">About Us</a></div>

                            <!-- <div class="my-4"><a [href]="jobsLink" target="_blank"
                                    class="text-white no-underline">Jobs</a></div> -->

                            <div class="my-4"><a [routerLink]="['/organization/jobs']"
                                    class="text-white no-underline">Jobs</a></div>

                        </div>

                        <div class="pb-6 lg:w-1/2 text-lg lg:text-base">

                            <div class="opacity-50 text-sm">INFO</div>

                            <div class="my-4"><a
                                    href="https://support.jamestowndistributors.com/hc/en-us/sections/360008415253-Shipping-FAQs"
                                    class="text-white no-underline" target="_blank">Shipping</a></div>

                            <div class="my-4"><a
                                    href="https://support.jamestowndistributors.com/hc/en-us/sections/360008415273-Order-Cancellation-Returns-FAQs"
                                    class="text-white no-underline" target="_blank">Returns</a></div>

                            <div class="my-4"><a
                                    href="https://support.jamestowndistributors.com/hc/en-us/sections/360008318334-Order-Payment-FAQs"
                                    class="text-white no-underline" target="_blank">Payment Info</a></div>

                            <div class="my-4"><a
                                    href="https://support.jamestowndistributors.com/hc/en-us/categories/360003089913-JD-Com-Info-and-Policy-Support"
                                    class="text-white no-underline" target="_blank">FAQs</a></div>

                            <div class="my-4"><a [routerLink]="['/organization/contact-us']"
                                    class="text-white no-underline">Contact Us</a></div>

                        </div>

                    </div>

                    <div class="md:w-1/2 lg:flex">

                        <div class="pb-6 lg:w-1/2 text-lg lg:text-base">

                            <div class="opacity-50 text-sm">SUPPORT</div>

                            <div class="my-4"><a
                                    href="https://support.jamestowndistributors.com/hc/en-us/categories/360001134314-Product-Support"
                                    target="_blank" class="text-white no-underline">Product Support</a></div>

                            <!-- <div class="my-4"><a href="#" class="text-white no-underline">Project Guides</a></div> -->

                            <div class="my-4"><a [href]="helpCenterURL" target="_blank"
                                    class="text-white no-underline">Help Center</a></div>

                        </div>

                        <div class="pb-6 lg:w-1/2">

                            <div class="opacity-50 text-sm hidden xl:block pb-3">STAY CONNECTED</div>

                            <div class="-mx-4 sm:-mx-6 md:-mx-4 text-lg lg:text-base">

                                <a [href]="facebookLink | safe" target="_blank" aria-label="Facebook - jamestown Distributors">
                                    <i
                                        class="fab fa-facebook-f fa-2x text-white no-underline my-2 mx-4 sm:mx-6 md:mx-4"></i></a>

                                <a [href]="youtubeLink | safe" target="_blank" aria-label="Youtube - jamestown Distributors"><i
                                        class="fab fa-youtube fa-2x text-white no-underline my-2 mx-4 sm:mx-6 md:mx-4"></i></a>

                                <a [href]="instagramLink | safe" target="_blank" aria-label="Instagram - jamestown Distributors"><i
                                        class="fab fa-instagram fa-2x text-white no-underline my-2 mx-4 sm:mx-6 md:mx-4"></i></a>

                                <a [href]="twitterLink | safe" target="_blank" aria-label="Twitter - jamestown Distributors"><i
                                        class="fab fa-twitter fa-2x text-white no-underline my-2 mx-4 sm:mx-6 md:mx-4"></i></a>

                            </div>

                            <div
                                class="pt-3 pb-6 hidden md:block xl:hidden -mx-3 text-lg lg:text-base footer__cols__legal-links">

                                <!--<div class="p-3 inline-block"><a href="#" class="text-white no-underline">Terms of Use</a></div>-->

                                <div class="p-3 inline-block"><a
                                        href="https://support.jamestowndistributors.com/hc/en-us/sections/360008318454-Privacy-Policy-FAQs"
                                        target="_blank" class="text-white no-underline">Privacy
                                        Policy</a>
                                </div>

                                <br>

                                <div class="p-3 inline-block"><a
                                        href="https://support.jamestowndistributors.com/hc/en-us/sections/360008318334-Order-Payment-FAQs"
                                        target="_blank" class="text-white no-underline">Credit Card
                                        Policy</a></div>

                                <div class="p-3 inline-block"><a
                                        href="https://support.jamestowndistributors.com/hc/en-us/articles/360048400793-What-information-do-you-collect-about-people-who-visit-your-website-"
                                        target="_blank" class="text-white no-underline">Cookie
                                        Policy</a>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="xl:flex xl:opacity-50 footer__legal">

                    <div class="text-center md:text-left pb-6 xl:w-1/2 xl:pt-3 lg:text-sm footer__legal__copyright">
                        &copy; {{rightsReservedYear}} Jamestown Distributors, Inc.
                        <br class="md:hidden">All Rights reserved.
                    </div>

                    <div class="text-center pb-6 md:hidden xl:block xl:w-1/2 text-sm lg:text-13px footer__legal__links">

                        <!--<div class="p-3 inline-block"><a href="#" target_="_blank" class="text-white no-underline">Terms of Use</a></div>-->

                        <div class="p-3 inline-block"><a [href]="privacyPolicy" target="_blank"
                                class="text-white no-underline">Privacy Policy</a>
                        </div>

                        <br class="xl:hidden">

                        <div class="p-3 inline-block"><a
                                href="https://support.jamestowndistributors.com/hc/en-us/sections/360008318334-Order-Payment-FAQs"
                                target="_blank" class="text-white no-underline">Credit Card Policy</a>
                        </div>

                        <div class="p-3 inline-block"><a
                                href="https://support.jamestowndistributors.com/hc/en-us/articles/360048400793-What-information-do-you-collect-about-people-who-visit-your-website-"
                                target="_blank" class="text-white no-underline">Cookie Policy</a>
                        </div>

                    </div>

                </div>

            </div>
            <!--/.container-->
        </footer>
        <!-- Footer End-->

        <!-- Overlay for modal -->
        <ng-template #loginComponent>
            <a href="javascript:void(0)" class="js-close-modal text-grey text-4xl modal__close" id="jsCloseModal" (click)="loginPopup('close')">
                <i class="fal fa-times"></i>
            </a>
            <app-login [modalView]="true" (closeModal)="toggleModal();"></app-login>
        </ng-template>

        <app-modal [template]="loginComponent" [active]="active"></app-modal>

        <!-- Global Search Results -->
        <div class="search-results-inline rounded-lg bg-white overflow-hidden whitelisted"
            (focus)="showGlobalSearch = true" style="top: 80.1421px; width: 90%;">
            <!-- Mobile View -->
            <div class="search-results-text p-10 lg:hidden whitelisted">
                <a *ngFor="let term of intelliSuggestResponse?.terms" [routerLink]="['/product']"
                    [queryParams]="{ queryBy: '&q=' + term.query}" (click)="hideGlobalSearch()"
                    class="block mb-1 text-grey-darker no-underline whitelisted">
                    <span class="text-black whitelisted" [innerHtml]="term.query | replaceElement"></span>
                </a>
                <a *ngFor="let alternative of intelliSuggestResponse?.alternatives" [routerLink]="['/product']"
                    [queryParams]="{ queryBy: '&q=' + alternative.text}" (click)="hideGlobalSearch()"
                    class="block mb-1 text-grey-darker no-underline whitelisted">
                    <span class="text-black whitelisted" [innerHtml]="alternative.text | replaceElement"></span>
                </a>
            </div>
            <!-- Mobile View -->

            <!-- Desktop View -->
            <div class="search-results-rich hidden lg:flex whitelisted">
                <div
                    class="bg-grey-blue pt-4 flex flex-col justify-between flex-no-shrink search-results-rich__text-col whitelisted">
                    <div class="whitelisted">
                        <a [tabindex]="term.searchResultTabIndex || -1" *ngFor="let term of intelliSuggestResponse?.terms" [routerLink]="['/product']"
                            [queryParams]="{ queryBy: '&q=' + term.query}" (click)="hideGlobalSearch()"
                            class="block py-4 px-10 text-grey-light no-underline bg-grey-blue-darker hover:bg-grey-blue-darker whitelisted">
                            <span class="text-white whitelisted" [innerHtml]="term.query | replaceElement"></span>
                        </a>
                        <a [tabindex]="alternative.searchResultTabIndex || -1" *ngFor="let alternative of intelliSuggestResponse?.alternatives" [routerLink]="['/product']"
                            [queryParams]="{ queryBy: '&q=' + alternative.text}" (click)="hideGlobalSearch()"
                            class="block py-4 px-10 text-grey-light no-underline bg-grey-blue-darker hover:bg-grey-blue-darker whitelisted">
                            <span class="text-black whitelisted" [innerHtml]="alternative.text | replaceElement"></span>
                        </a>
                    </div>
                    <!-- Ad placeholder -->
                    <div class="p-3 pb-2">
                        <img [src]="'./../assets/img/search-ad.jpg'"
                            [ngClass]="{'cursor-pointer': !isSearchTextEntered}"
                            (click)="navigateWithQuery(searchForm.controls.query.value)" appImageLazyLoad loading="lazy"
                            class="search-results-rich__ad whitelisted" alt="search-ad">
                    </div>
                    <!-- Ad placeholder -->
                </div>
                <div class="pt-4 px-6 w-full search-results-rich__list-col whitelisted srchPopup">
                    <div *ngIf="intelliSuggestResponse?.isCorrectQuery; else noCorrectQuery" class="whitelisted">
                        <div>
                            <div class="font-display srchCorrected"><span class="font-bold">Showing results for
                                </span><span class="text-blue">"<span
                                        class="underline">{{intelliSuggestResponse?.correctedQuery}}</span>"</span>
                            </div>
                            <div class="font-display srchOriginal">Search for <span
                                    class="text-blue">{{intelliSuggestResponse?.query}}</span> instead</div>
                        </div>
                    </div>
                    <ng-template #noCorrectQuery>
                        <div class="whitelisted">
                            <div>
                                <div class="font-display srchCorrected"><span class="font-bold">Showing results
                                        for</span> <span class="text-blue">"<span
                                            class="underline">{{intelliSuggestResponse?.query}}</span>"</span></div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="flex flex-wrap search-results-rich__list__container mt-4">
                        <a [tabindex]="product.searchResultTabIndex" class="product-list__item no-underline"
                            (click)="showHideGlobalSearch(false);searchForm.reset()"
                            [routerLink]="['/product/product-detail/' + product.sku]"
                            [state]="{ data: 'search (' + intelliSuggestResponse?.query+')'}"
                            *ngFor="let product of intelliSuggestResponse?.products">
                            <div>
                                <img [src]="product?.thumbnailImageUrl" appImageLazyLoad loading="lazy"
                                    [alt]="product?.name">
                            </div>
                            <div class="mt-3 font-display text-grey uppercase text-lg">{{product?.brand}}</div>
                            <div class="text-sm text-black"> <strong><span
                                        [innerHtml]="product?.name | replaceElement"></span></strong>
                            </div>
                            <div class="mb-3 text-black text-sm"><em>Starting at {{product?.price | currency}}</em>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!-- Desktop View -->

        </div>
        <!-- Global Search Results -->
    </div>
</div>