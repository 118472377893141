import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { StorageService } from '../shared/services/storage.service';
import { CartService } from '../cart/cart.service';
import { mergeMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckoutResolveService  {
  zipCode;

  constructor(
    private storageService: StorageService,
    private cartService: CartService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    if (route.queryParams && route.queryParams.prop === 'viewInvoice') {
      return of(true);
    }
    if (this.storageService.getItem('zipCode')) {
      this.zipCode = this.storageService.getItem('zipCode');
    }
    const cartId = this.storageService.getItem('cartId');
    if (this.storageService.getItem('idToken')) {
      return this.cartService.getShoppingCartById(cartId).pipe(
        mergeMap(shoppingCartDetails => {
          return this.getShippingMethod(shoppingCartDetails);
        }));
    } else {
      if (cartId) {
        return this.cartService.getShoppingCartByIdWithoutAccount(cartId).pipe(
          mergeMap(shoppingCartDetails => {
            return this.getShippingMethod(shoppingCartDetails);
          }));
      }
    }
  }

  // Get Shipping methods
  getShippingMethod(shoppingCartDetails: any): any {
    if (shoppingCartDetails && shoppingCartDetails.skus && shoppingCartDetails.skus.length > 0) {
      const subTotal = shoppingCartDetails.skus.map(product => product.quantity * product.sellPrice).reduce((a, b) => a + b);
      const skusList = shoppingCartDetails.skus.map(product => product.id);
      const skusQuantity = shoppingCartDetails.skus.map(product => product.quantity);
      if (this.zipCode) {
        let queryUrl = `postal_code=${this.zipCode}&order_subtotal=${subTotal}&skus=${skusList.toString()}&sku_qty=${skusQuantity.toString()}`;
        if (this.storageService.getItem('idToken')) {
          queryUrl += `&email_address=${this.storageService.getItem('currentUser').email}`;
        }
        return this.cartService.getShippingMethods(queryUrl).pipe(
          map(orderSummaryDetails => {
            return {
              shoppingCartData: shoppingCartDetails,
              orderSummary: orderSummaryDetails
            };
          })
        );
      } else {
        return of({
          shoppingCartData: shoppingCartDetails,
          orderSummary: {}
        });
      }
    } else {
      return of(true);
    }
  }
}
